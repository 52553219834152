import { SET_AUTH_USER } from "./actionTypes";

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.me,
        households: action.payload.myHouseholdMemberships,
      };
    default:
      return state;
  }
};
