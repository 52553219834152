import React, { createContext, useReducer, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { reducer } from "./reducer";
import { SET_AUTH_USER } from "./actionTypes";
import { GET_AUTH_USER_INFO, WELCOME_QUERY } from "graphql/queries/user";
import { AUTH_TOKEN, errorMessages } from "utils/constants";

const initialState = {
  isAuthenticated: false,
  user: {},
  households: [],
};
export const AuthContext = createContext(initialState);
const token = localStorage[AUTH_TOKEN];
let query = token ? GET_AUTH_USER_INFO : WELCOME_QUERY;

export const AuthProvider = ({ children }) => {
  const { data, error } = useQuery(query, {
    errorPolicy: "ignore",
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  const { Provider } = AuthContext;
  useEffect(() => {
    if (data?.me) {
      dispatch({ type: SET_AUTH_USER, payload: data });
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      console.log({ error });
      localStorage.clear();
      switch (error.message) {
        case errorMessages.noPermission:
          console.log("nP");
          if (window.location.pathname !== "/sign-in") {
            window.location.replace("/sign-in");
          }
          break;
        case errorMessages.decodeToken:
          console.log("dT");
          window.location.replace("/sign-in");
          break;
        default:
          console.log("def");
          break;
      }
    }
  }, [error]);
  console.log({ error });
  return (
    <Provider
      value={{
        ...state,
        setAuthUser: (userInfo) =>
          dispatch({ type: SET_AUTH_USER, payload: userInfo }),
        dispatch,
      }}
    >
      {children}
    </Provider>
  );
};
