import React, { useContext, useState } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/styles";
import { Button, useMediaQuery } from "@material-ui/core";

import { Sidebar, Topbar, Footer } from "../components/common";
import { useLayoutStyles } from "./styles";
import { AuthContext } from "contextStates/store";
import { Alert, AlertTitle } from "@material-ui/lab";

export const Main = (props) => {
  const { children } = props;
  const { user } = useContext(AuthContext);
  const classes = useLayoutStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
      />
      <main className={classes.content}>
        {user.householdSet?.length === 0 && (
          <Alert
            severity="error"
            action={<Button size="small">Click to set it up</Button>}
          >
            <AlertTitle>Missing configurations(Household)</AlertTitle>
            Required configuration — <strong>
              Household, Family group.
            </strong>{" "}
            This configiration will allow you and your family members to use the{" "}
            <strong>same account</strong> with different privelegies
          </Alert>
        )}
        {children}
        <Footer />
      </main>
    </div>
  );
};
